
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






















































.choose-voo-my__hero {
  height: auto;
  margin-top: $spacing * 5 !important;
  background-color: transparent;

  @include mq(l) {
    margin-top: $spacing * 6 !important;
  }
}
.choose-voo-my__cards {
  ::v-deep .store-img {
    display: inline-block;
    margin-top: $spacing * 3;

    img {
      height: 4rem;
    }
  }
}
